import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core'

import s from './styles.module.css'

library.add(faSpinner as IconDefinition)

const Spinner = ({ className }: { className?: string }) => (
  <FontAwesomeIcon
    className={cx(s.spinner, className)}
    icon="spinner"
    spin
    pulse
  />
)

export default Spinner
