import { forwardRef } from 'react'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import s from './styles.module.css'

const StatelessTextInput = forwardRef((props, ref) => {
  const {
    error,
    isInvalid,
    label,
    isV2,
    icon,
    inputClassName,
    labelClassName,
    wrapperClassName,
    errorClassName,
    ...inputProps
  } = props

  return (
    <div className={cx(s.wrapper, wrapperClassName)}>
      {label && <div className={cx(s.label, labelClassName)}>{label}</div>}
      <div className={s.inputWrapper}>
        {icon && <FontAwesomeIcon icon={icon} className={s.icon} />}
        <input
          {...inputProps}
          ref={ref}
          type="text"
          className={cx(
            {
              [s.inputInvalid]: !!error || isInvalid,
              [s.input]: !isV2,
              [s.inputV2]: isV2,
              [s.iconInput]: !!icon,
            },
            inputClassName
          )}
        />
      </div>
      {error && <div className={errorClassName || s.error}>{error}</div>}
    </div>
  )
})

export default StatelessTextInput
