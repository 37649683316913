import * as R from 'ramda'
import { useState, useEffect, useRef } from 'react'

import StatelessTextInput from 'components/shared/stateless-text-input'

const TextInput = props => {
  const {
    value: initialValue,
    error: propError,
    onChange = () => {},
    onChangeDetailed = () => {},
    onBlur = () => {},
    getError = () => null,
    parseValue = R.identity,
    formatValue = R.identity,
    isInvalid = false,
    isV2 = false,
    isRequired = false,
    labelClassName,
    ...inputProps
  } = props

  const isInitialized = useRef(false)
  const [value, setValue] = useState(initialValue)
  const [error, setError] = useState(
    initialValue ? getError(initialValue) : null
  )

  const handleChange = ev => {
    setError(null)
    setValue(parseValue(ev.target.value))
  }

  const handleBlur = ev => {
    const error = getError(value)
    if (error) return setError(error)

    onBlur(value)
  }

  useEffect(() => {
    if (isInitialized.current) {
      onChange(value)

      const error = getError(value)
      onChangeDetailed({
        value,
        error,
        isValid: !error && !isInvalid && (!isRequired || !!value),
      })
    } else {
      isInitialized.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const onKeyDown = ev => {
    if (props.onKeyDown) {
      props.onKeyDown(ev)
    }
    if (ev.key === 'ArrowLeft' || ev.key === 'ArrowRight') {
      ev.stopPropagation()
    }
  }

  return (
    <StatelessTextInput
      {...inputProps}
      labelClassName={labelClassName}
      value={formatValue(value)}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={onKeyDown}
      error={error}
      isV2={isV2}
    />
  )
}

export default TextInput
