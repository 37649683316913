import React, { useEffect } from 'react'
import ErrorPage from 'next/error'
import Router from 'next/router'
import * as D from 'date-fns'

import { bugsnagNotifyAsync } from 'clients/bugsnag'
import { sleep } from 'utilities/promises'

const Error = ({ title, statusCode }) => {
  useEffect(() => {
    const refreshErrorPageWithDelay = async () => {
      const lastErrorDate = new Date(
        localStorage.getItem('last_error_date') || null
      )

      // Clean error env vars when last error is older than 1 day
      if (D.differenceInDays(new Date(), lastErrorDate) > 0) {
        localStorage.removeItem('refresh_error_count')
        localStorage.removeItem('last_error_date')
      }

      let refreshErrorCount =
        Number(localStorage.getItem('refresh_error_count')) || 0

      // Refresh page only thrice to prevent infinite loop
      if (refreshErrorCount < 3) {
        // Gradually wait for the next refresh (unless it's the first error occurrence)
        await sleep(refreshErrorCount * 10000)
        refreshErrorCount++

        localStorage.setItem('refresh_error_count', String(refreshErrorCount))
        localStorage.setItem('last_error_date', String(new Date()))

        Router.reload()
      }
    }

    refreshErrorPageWithDelay().catch(console.error)
  }, [])

  return <ErrorPage title={title} statusCode={statusCode || 'Error'} />
}

Error.getInitialProps = async ctx => {
  if (ctx.err) {
    await bugsnagNotifyAsync(ctx.err)
  }

  return {
    ...ErrorPage.getInitialProps(ctx),
    error: ctx.err,
  }
}

export default Error
