import React from 'react'
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
  Observable,
  HttpLink,
} from '@apollo/client'
import fetch from 'isomorphic-unfetch'
import { parseCookies } from 'nookies'

import bugsnagClient from 'clients/bugsnag'
import { SESSION_TOKEN } from 'constants/cookies'
import { getLocalUtcOffset } from 'utilities/dates'
import ErrorPage from 'pages/_error'
import { ENVIRONMENT_ENDPOINTS } from 'constants/api'
import possibleTypes from '../fragment-types.json'

let apolloClient = null

const ErrorBoundary = bugsnagClient
  .getPlugin('react')
  .createErrorBoundary(React)

const httpLink = new HttpLink({
  uri: ENVIRONMENT_ENDPOINTS[process.env.APP_ENV],
  fetch,
})

export function withApollo(PageComponent, { ssr = true } = {}) {
  const WithApollo = ({ apolloClient, apolloState, ...pageProps }) => {
    const client = apolloClient || initApolloClient(apolloState)

    return (
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <ApolloProvider client={client}>
          <PageComponent {...pageProps} />
        </ApolloProvider>
      </ErrorBoundary>
    )
  }

  // Set the correct displayName in development
  if (process.env.NODE_ENV !== 'production') {
    const displayName =
      PageComponent.displayName || PageComponent.name || 'Component'

    if (displayName === 'App') {
      console.warn('This withApollo HOC only works with PageComponents.')
    }

    WithApollo.displayName = `withApollo(${displayName})`
  }

  if (ssr || PageComponent.getInitialProps) {
    WithApollo.getInitialProps = async ctx => {
      const { AppTree } = ctx

      // Initialize ApolloClient, add it to the ctx object so
      // we can use it in `PageComponent.getInitialProp`.
      const apolloClient = (ctx.apolloClient = initApolloClient(undefined, ctx))

      // Run wrapped getInitialProps methods
      let pageProps = {}
      if (PageComponent.getInitialProps) {
        pageProps = await PageComponent.getInitialProps({ apolloClient, ctx })
      }

      // Only on the server:
      if (typeof window === 'undefined') {
        // When redirecting, the response is finished.
        // No point in continuing to render
        if (ctx.res && ctx.res.finished) {
          return pageProps
        }

        // Only if ssr is enabled
        if (ssr) {
          try {
            // Run all GraphQL queries
            const { getDataFromTree } = await import('@apollo/client/react/ssr')
            await getDataFromTree(
              <AppTree
                pageProps={{
                  ...pageProps,
                  apolloClient,
                }}
              />
            )
          } catch (error) {
            // Prevent Apollo Client GraphQL errors from crashing SSR.
            // Handle them in components via the data.error prop:
            // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
            console.error('Error while running `getDataFromTree`', error)
          }
        }
      }

      // Extract query data from the Apollo store
      const apolloState = apolloClient.cache.extract()

      return {
        ...pageProps,
        apolloState,
      }
    }
  }

  return WithApollo
}

const initApolloClient = (initialState, ctx) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return createApolloClient(initialState, ctx)
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = createApolloClient(initialState, ctx)
  }

  return apolloClient
}

let previousToken

const createApolloClient = (initialState = {}, ctx) => {
  const request = async operation => {
    // hack for auth in SSR
    const token =
      (ctx && ctx.overrideCookies && ctx.overrideCookies[SESSION_TOKEN]) ||
      parseCookies(ctx)[SESSION_TOKEN]

    // if (
    //   previousToken &&
    //   token !== previousToken &&
    //   typeof window !== 'undefined'
    // ) {
    //   throw new Error(`Authentication changed`)
    // }

    previousToken = token

    operation.setContext({
      headers: {
        'Cache-Control': 'no-cache',
        authorization: token ? `Bearer ${token}` : '',
        local_utc_offset: getLocalUtcOffset(),
      },
    })

    return operation
  }

  const verifyStaleAppVersionLink = new ApolloLink((operation, forward) =>
    forward(operation).map(response => {
      if (typeof window === 'undefined') return response

      const context = operation.getContext()
      const {
        response: { headers },
      } = context

      if (headers && headers.get('X-Version')) {
        const currentAppVersion = headers.get('X-Version')
        const lastLoadedVersion = localStorage.getItem('app_version')

        if (lastLoadedVersion && lastLoadedVersion !== 'null') {
          if (currentAppVersion !== lastLoadedVersion) {
            console.log('New app release detected! Setting refresh...')
            localStorage.setItem('app_version', null)
            localStorage.setItem('pending_refresh', 'true')
          }
        } else {
          localStorage.setItem('app_version', currentAppVersion)
        }
      }

      return response
    })
  )

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle
        Promise.resolve(operation)
          .then(request)
          .then(oper => {
            handle = forward(oper).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            })
          })
          .catch(observer.error.bind(observer))

        return () => {
          if (handle) handle.unsubscribe()
        }
      })
  )

  return new ApolloClient({
    name: 'Web',
    version: '1.2.7',
    ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([verifyStaleAppVersionLink, requestLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            workout: {
              keyArgs: ['id'],
              read(_, { args, toReference }) {
                return toReference({ __typename: 'Workout', id: args.id })
              },
            },
            client: {
              keyArgs: ['id'],
              read(_, { args, toReference }) {
                return toReference({ __typename: 'Client', id: args.id })
              },
            },
          },
        },
      },
      possibleTypes,
    }).restore(initialState || {}),
  })
}
