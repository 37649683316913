import * as R from 'ramda'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import cx from 'classnames'

import s from './styles.module.css'
import Image from 'next/image'

const CONTENT_RENDERERS = {
  text: (node, isFirstItem, idx) => {
    const marks = new Set(R.map(R.prop('type'), node.marks))

    const boldText = isFirstItem ? R.head(node.value) : ''
    const normalText = isFirstItem ? R.tail(node.value) : node.value

    if (R.isEmpty(normalText)) return <br key={`br-${idx}`} />

    return (
      <span
        key={`text-${idx}`}
        className={cx({
          [s.bold]: marks.has('bold'),
          [s.underline]: marks.has('underline'),
          [s.italic]: marks.has('italic'),
        })}>
        {boldText && <strong className={s.firstLetter}>{boldText}</strong>}
        {normalText}
      </span>
    )
  },
  [BLOCKS.HEADING_1]: (node, _, idx) => {
    return (
      <h1 key={`contentful-h1-${idx}`} className={s.h1}>
        {renderContentItems(node.content)}
      </h1>
    )
  },
  [BLOCKS.HEADING_2]: (node, _, idx) => {
    return (
      <h2 key={`contentful-h2-${idx}`} className={s.h2}>
        {renderContentItems(node.content)}
      </h2>
    )
  },
  [BLOCKS.HEADING_3]: (node, _, idx) => {
    return (
      <h3 key={`contentful-h3-${idx}`} className={s.h3}>
        {renderContentItems(node.content)}
      </h3>
    )
  },
  [BLOCKS.HEADING_4]: (node, _, idx) => {
    return (
      <h4 key={`contentful-h4-${idx}`} className={s.h4}>
        {renderContentItems(node.content)}
      </h4>
    )
  },
  [BLOCKS.HEADING_5]: (node, _, idx) => {
    return (
      <h5 key={`contentful-h5-${idx}`} className={s.h5}>
        {renderContentItems(node.content)}
      </h5>
    )
  },
  [BLOCKS.HEADING_6]: (node, _, idx) => {
    return (
      <h6 key={`contentful-h6-${idx}`} className={s.h6}>
        {renderContentItems(node.content)}
      </h6>
    )
  },
  [BLOCKS.PARAGRAPH]: (node, isFirstItem, idx) => {
    return (
      <p key={`contentful-paragraph-${idx}`} className={s.p}>
        {renderContentItems(node.content, isFirstItem)}
      </p>
    )
  },
  [BLOCKS.OL_LIST]: (node, _, idx) => {
    return (
      <ol key={`contentful-ordered-list-${idx}`} className={s.ol}>
        {renderContentItems(node.content)}
      </ol>
    )
  },
  [BLOCKS.UL_LIST]: (node, _, idx) => {
    return (
      <ul key={`contentful-unordered-list-${idx}`} className={s.ul}>
        {renderContentItems(node.content)}
      </ul>
    )
  },
  [BLOCKS.LIST_ITEM]: (node, _, idx) => {
    return (
      <li key={`contentful-list-item-${idx}`} className={s.li}>
        {renderContentItems(node.content)}
      </li>
    )
  },
  [BLOCKS.QUOTE]: (node, _, idx) => {
    return (
      <blockquote key={`contentful-blockquote-${idx}`} className={s.blockquote}>
        {renderContentItems(node.content)}
      </blockquote>
    )
  },
  [INLINES.HYPERLINK]: ({ content, data: { uri } }, _, idx) => {
    return (
      <a
        key={`hyperlink-${idx}`}
        href={uri}
        className={s.link}
        target="_blank"
        rel="noopener noreferrer">
        {renderContentItems(content)}
      </a>
    )
  },
  [BLOCKS.EMBEDDED_ASSET]: (node, _, idx) => {
    const {
      title,
      description,
      file: { contentType, url },
    } = node.data.target.fields

    if (R.test(/video\//, contentType)) {
      return (
        <figure key={`contentful-video-${idx}`}>
          <video
            alt={title}
            className={s.video}
            src={`https:${url}?w=1000#t=0.4`}
            preload="metadata"
            controls
          />
          <figcaption>{description || title}</figcaption>
        </figure>
      )
    } else if (R.test(/image\/gif/, contentType)) {
      return (
        <figure key={`contentful-image-${idx}`}>
          <div className={s.image}>
            <Image
              alt={title}
              src={`https:${url}?fl=progressive&w=1000`}
              layout="fill"
              objectFit="cover"
            />
          </div>
          <figcaption>{description || title}</figcaption>
        </figure>
      )
    } else if (R.test(/image\//, contentType)) {
      return (
        <figure key={`contentful-image-${idx}`}>
          <div className={s.image}>
            <Image
              alt={title}
              src={`https:${url}?fm=jpg&fl=progressive&w=1000`}
              layout="fill"
              objectFit="cover"
            />
          </div>
          <figcaption>{description || title}</figcaption>
        </figure>
      )
    } else {
      return title
    }
  },
  [BLOCKS.TABLE]: (node, _, idx) => (
    <table key={`contentful-table-${idx}`}>
      <tbody>{renderContentItems(node.content)}</tbody>
    </table>
  ),
  [BLOCKS.TABLE_ROW]: (node, _, idx) => (
    <tr key={`contentful-table-row-${idx}`}>
      {renderContentItems(node.content)}
    </tr>
  ),
  [BLOCKS.TABLE_HEADER_CELL]: (node, _, idx) => (
    <th key={`contentful-table-head-${idx}`}>
      {renderContentItems(node.content)}
    </th>
  ),
  [BLOCKS.TABLE_CELL]: (node, _, idx) => (
    <td key={`contentful-table-data-${idx}`}>
      {renderContentItems(node.content)}
    </td>
  ),
  _DEFAULT: node => JSON.stringify(node),
}

export const renderContentItem = (item, isFirstItem = false, idx) => {
  const renderer =
    CONTENT_RENDERERS[item.nodeType] || CONTENT_RENDERERS._DEFAULT

  return renderer(item, isFirstItem, idx)
}

export const renderContentItems = (items, isFirstItem = false) => {
  return R.addIndex(R.map)((item, idx) => {
    return renderContentItem(item, isFirstItem && idx === 0, idx)
  }, items)
}

const renderEmptyParagraphs = children => {
  return children.map((c, i) => {
    if (typeof c === 'string' && R.isEmpty(c) && children.length === 2)
      return <br key={`empty-paragraph-${i}`} />
    if (!Array.isArray(c)) return c
    return renderEmptyParagraphs(c)
  })
}

export const CUSTOM_RENDERERS = {
  MARKS: {
    [MARKS.BOLD]: text => <span className={s.bold}>{text}</span>,
    [MARKS.ITALIC]: text => <span className={s.italic}>{text}</span>,
    [MARKS.UNDERLINE]: text => <span className={s.underline}>{text}</span>,

    /* TODO */
    // [MARKS.CODE]: text => <span>{text}</span>,
  },
  BLOCKS: {
    [BLOCKS.HEADING_1]: (node, children) => {
      return <h1 className={s.h1}>{children}</h1>
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2 className={s.h2}>{children}</h2>
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return <h3 className={s.h3}>{children}</h3>
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return <h4 className={s.h4}>{children}</h4>
    },
    [BLOCKS.HEADING_5]: (node, children) => {
      return <h5 className={s.h5}>{children}</h5>
    },
    [BLOCKS.HEADING_6]: (node, children) => {
      return <h6 className={s.h6}>{children}</h6>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      children = renderEmptyParagraphs(children)
      return <p className={s.p}>{children}</p>
    },
    [BLOCKS.OL_LIST]: (node, children) => {
      return <ol className={s.ol}>{children}</ol>
    },
    [BLOCKS.UL_LIST]: (node, children) => {
      return <ul className={s.ul}>{children}</ul>
    },
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return <li className={s.li}>{children}</li>
    },
    [BLOCKS.QUOTE]: (node, children) => {
      return <blockquote className={s.blockquote}>{children}</blockquote>
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      const {
        title,
        file: { contentType, url },
      } = node.data.target.fields

      if (R.test(/video\//, contentType)) {
        return (
          <video
            alt={title}
            className={s.video}
            src={`https:${url}?w=1000#t=0.04`}
            preload="metadata"
            controls
          />
        )
      } else if (R.test(/image\//, contentType)) {
        return (
          <div className={s.image}>
            <Image
              alt={title}
              src={`https:${url}?fm=jpg&fl=progressive&w=1000`}
              layout="fill"
              objectFit="cover"
            />
          </div>
        )
      } else {
        return title
      }
    },
  },
  INLINES: {
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <a
          href={node.data.uri}
          className={s.link}
          target="_blank"
          rel="noopener noreferrer">
          {children}
        </a>
      )
    },
    /* TODO */
    // [INLINES.EMBEDDED_ENTRY]: () => '',
    // [INLINES.ENTRY_HYPERLINK]: () => '',
    // [INLINES.ASSET_HYPERLINK]: () => '',
  },
}

export const renderHeaderWordEmphasis = (text, emphasisWord) => {
  if (!emphasisWord) return text
  const result = text.split(' ').map(word => {
    if (emphasisWord.includes(word)) {
      return (
        <span key={word} className="emphasis">
          {word}{' '}
        </span>
      )
    }
    return word + ' '
  })
  return result
}
