import { ReactNode } from 'react'

export const BaseFontProvider = ({
  font,
  children,
}: {
  font: { style: { fontFamily: string } }
  children: ReactNode
}) => {
  return (
    <>
      <style jsx global>
        {`
          html {
            font-family: ${font.style.fontFamily};
          }
        `}
      </style>
      {children}
    </>
  )
}
